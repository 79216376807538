<template>
  <div>
    <el-card class="box-card" style="margin-bottom: 5px; text-align: center">
      <div v-if="!!user">
        <div slot="header" class="clearfix" style="font-weight: bold">
          <span>用户名：{{ user.username }}</span>
        </div>
        <div class="text item" style="margin-top: 5px">
          <span>用户姓名：{{ user.name }}</span>
        </div>
        <div class="text item" style="margin-top: 5px">
          <span>手机：{{ user.mobile }}</span>
        </div>
      </div>
    </el-card>
    <div style="margin-top: 20px">
      <el-form ref="form" :model="cuttData" :rules="rules">
        <el-form-item label="企业名称" label-width="100px" prop="companyName">
          <el-input v-model="cuttData.companyName" maxlength="30"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dlgShow = false">取 消</el-button>
        <el-button type="primary" @click="handle_validate('form')"
          >确 定</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import EMPLOYER from "../../api/lianying/api_employer";
import USER from "../../api/lianying/api_user";
import scaleList from "../../common/scale.js";
import provinces from "../../common/province.js";
import cities from "../../common/city.js";
import areas from "../../common/area.js";
import * as API from "../../utils/request";
import moment from "moment";

export default {
  name: "editEmployer", // 编辑雇主

  data() {
    return {
      cuttData: {},
      user: {},
      rules: {
        companyName: [
          { required: true, message: "请输入雇主公司名称", trigger: "blur" },
          {
            min: 1,
            max: 100,
            message: "长度在 1 到 30 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  // 初始化加载
  mounted() {
    this.init();
  },
  computed: {},
  // 方法定义
  methods: {
    init() {
      this.initPageData();
    },
    initPageData() {
      let id = this.$route.query.id;
      console.log("id " + id);
      if (id) {
        USER.get({
          id: id,
        }).then((res) => {
          console.log("init user " + JSON.stringify(res));
          this.showLoading = false;
          if (1 == res.status) {
            this.user = res.data;
          } else {
            this.$message.error(res.message);
            //this.initAddData();
          }
        });
      } else {
        this.$message.error("没有找到注册用户");
      }
    },

    handle_validate(ref) {
      this.$refs[ref].validate((valid) => {
        if (valid) {
          this.handle_save();
        } else {
          return false;
        }
      });
    },
    async handle_save() {
      this.lock = true;
      console.log("save employer " + JSON.stringify(this.cuttData));
      let params = {};
      params.companyName = this.cuttData.companyName;
      params.id = this.user.id;
      USER.upgradeEmployer(params).then(this.res_handle);
    },
    //结果请求设置
    res_handle(res) {
      console.log("res " + JSON.stringify(res));
      this.dlgShow = this.lock = false;
      if (1 === res.status) {
        this.$message.success("操作成功！");
        //this.init();
        this.$router.push({
          path: "/employer/list",
          query: {},
        });
      } else {
        this.$message.error(res.message);
      }
    },
  },
};
</script>

<style scoped>
</style>
